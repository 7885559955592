<template>
<div>
    <v-dialog v-model="$store.state.dialog2" width="1200">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>تفاصيل الخطوة</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form">
                <Loading v-if="loading" />
                <v-container v-if="!loading">
                    <v-card outlined>
                        <v-container>
                            <v-btn @click="print(body.permitDatas)" class="mb-12" large color="primary" v-if="$store.state.itemDetails.sequence == 3">
                                <h3>طباعة التخاويل</h3>
                            </v-btn>
                            <v-row>

                                <v-data-table :item-class="row_classes" class="mytable" :items="body.permitDatas" :loading="$global.state.loading" :headers="headers" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
                                    <template v-slot:item.actions="{item}">

                                        <v-tooltip bottom="" v-if="$store.state.itemDetails.sequence == 3 && item.isReject == false">
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click="print([item])" v-on="on" icon>
                                                    <v-icon color="error">
                                                        print
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>طباعة التخويل</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                                
                            </v-row>
                            <h3 class="mt-6" v-if="$store.state.user.role == 'Security' && $store.state.itemDetails.secretNote != null">ملاحظة سرية:  {{$store.state.itemDetails.secretNote}}</h3>
                            <v-card outlined class="mt-6" v-if="$store.state.itemDetails.textEditor">
                                <div  v-html="$store.state.itemDetails.textEditor"/>
                            </v-card>
                        </v-container>
                    </v-card>

                </v-container>
            </v-form>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            body: {},
            loading: false,
            headers: [{
                    text: 'الإسم الثلاثي للزائر',
                    value: 'fullName'
                },
                {
                    text: 'الصفة',
                    value: 'jobTitle'
                },
                {
                    text: 'الجهة الزائرة',
                    value: 'visiting'
                },
                {
                    text: 'الأجهزة',
                    value: 'devices'
                },
                {
                    text: 'نوع العجلة',
                    value: 'carName'
                },
                {
                    text: 'لون العجلة',
                    value: 'carColor'
                },
                {
                    text: 'موديل العجلة',
                    value: 'caryear'
                },
                {
                    text: 'نوع التخويل',
                    value: 'type'
                },
                {
                    text: 'ملاحظة',
                    value: 'note'
                },
                {
                    text: '',
                    value: 'actions'
                },
            ]
        }
    },

    mounted() {
        this.$eventBus.$on('fill-fields', () => {
            console.log('opp')
            this.reset();
            this.getItems();
        })
    },

    methods: {

        row_classes(item) {
            if (item.isReject == true) {
                return "pink darken-3 white--text"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },

        async print(data) {
            // await this.$htmlToPaper('printMe');
            console.log('go1')
            let validData= data.filter(x => x.isReject == false)
            await sessionStorage.setItem("printPermit", JSON.stringify(validData));
            this.$router.push('/printPermit')
        },

        reset() {
            this.body = {
                "sequence": this.$store.state.user.role == 'Security' ? 2 : 1,
                "permitId": this.$route.params.permitId,
                "textEditor": "",
                "secretNote": "",
                "userId": this.$store.state.user.id,
                "permitStepGroups": [],
                "permitDatas": []
            }
        },

        async getItems() {
            this.loading = true
            try {
                var res = await this.$http.get(`PermitData?PermitStepId=${this.$store.state.itemDetails.id}`)
                console.log(res)
                this.body.permitDatas = res.data.result
                for (let i = 0; i < this.body.permitDatas.length; i++) {
                    this.body.permitDatas[i].type = this.body.permitDatas[i].type.split(',')

                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },

        close() {
            this.$store.commit('setDialog2')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
